import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { ISettingsPath } from "../../models/interfaces/ISettingsPath.interface";
import { MatRippleModule } from "@angular/material/core";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { menuSettings } from "./menu.settings";
import { Router } from "@angular/router";

@Component({
  selector: "app-settings",
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatRippleModule,
    HeaderComponent,
  ],
  templateUrl: "./settings.component.html",
  styleUrl: "./settings.component.scss",
})
export class SettingsComponent {
  settingsList: ISettingsPath[] = menuSettings;

  constructor(private router: Router) {}

  clickHandler(route: string) {
    if (route) {
      this.router.navigate([route]);
    }
  }
}
