import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { LoaderService } from "../../../services/loader/loader.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-loader",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./loader.component.html",
  styleUrl: "./loader.component.scss",
})
export class LoaderComponent implements OnInit {
  isLoading = new Observable<boolean>();

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.isLoading = this.loaderService.getLoadComponentState();
  }
}
