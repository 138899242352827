<div class="db-card-container" [ngClass]="bgColor">
  <div class="header-layout">
    <mat-icon class="material-icons-sharp">{{ iconPath }}</mat-icon>
    <button mat-button [matMenuTriggerFor]="menu" class="btn-menu-profil">
      <span style="font-size: large; font-weight: bolder; color: #fff"
        >. . .</span
      >
    </button>
    <mat-menu #menu="matMenu" class="mat-menu">
      <button mat-menu-item (click)="click()">
        <span> Voir plus</span>
      </button>
    </mat-menu>
  </div>
  <div class="content-layout">
    <span class="value-container">{{ value }}</span>
  </div>
  <div class="footer-layout">{{ label }}</div>
</div>
