import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { catchError, throwError } from "rxjs";
import Swal from "sweetalert2";

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  let authToken = sessionStorage.getItem("accessToken");
  if (authToken) authToken = JSON.parse(authToken);

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return next(authReq).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          // Specific handling for unauthorized errors
          Swal.fire({
            title: "Non autorisé",
            text: "Vous n'êtes pas autorisé à acceder à cette fonctionnalité. Contactez votre administrateur.",
            icon: "error",
          });
        } else {
          // Handle other HTTP error codes
          console.error("HTTP error:", err);
        }
      } else {
        // Handle non-HTTP errors
        console.error("An error occurred:", err);
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    })
  );
};
