export const statusMarital = [
  {
    label: "Marié(e)",
    value: "married",
  },
  {
    label: "Divorcé(e)",
    value: "divorced",
  },
  {
    label: "Celibataire",
    value: "single",
  },
  {
    label: "Veuf(ve)",
    value: "widowed",
  },
];
