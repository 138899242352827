<mat-drawer-container class="container" autosize="true">
  <mat-drawer #drawer class="sidenav" mode="side" [opened]="true">
    @if (isSidebarOpened) {
    <div class="main-container">
      <div class="main-title">
        <img
          src="/assets/img/logo.png"
          alt="logo"
          width="200px"
          *ngIf="isSidebarOpened" />
      </div>
      <div class="menu-layout">
        <ng-container *ngFor="let menu of MenuList">
          <app-menu-item
            [isActive]="menu.isActive"
            [title]="menu.title"
            [iconPath]="menu.iconPath"
            routerLinkActive="active"
            (onMenuClick)="redirectTo(menu.route)"></app-menu-item>
        </ng-container>
      </div>
      <div class="action-layout">
        <ng-container *ngFor="let menu of actionList">
          <app-menu-item
            [title]="menu.title"
            [iconPath]="menu.iconPath"
            routerLinkActive="active"
            (onMenuClick)="redirectTo(menu.route)"></app-menu-item>
        </ng-container>
      </div>
    </div>
    } @else {
    <div class="main-container reduced-sidebar">
      <div class="main-title">
        <img src="/assets/img/logo.png" alt="logo" width="100px" />
      </div>
      <div class="menu-layout">
        <ng-container *ngFor="let menu of MenuList">
          <app-menu-item
            [isActive]="menu.isActive"
            [title]="menu.title"
            [iconPath]="menu.iconPath"
            routerLinkActive="active"
            [iconOnly]="true"
            (onMenuClick)="redirectTo(menu.route)"></app-menu-item>
        </ng-container>
      </div>
      <div class="action-layout">
        <ng-container *ngFor="let menu of actionList">
          <app-menu-item
            [title]="menu.title"
            [iconOnly]="true"
            [iconPath]="menu.iconPath"
            (onMenuClick)="redirectTo(menu.route)"></app-menu-item>
        </ng-container>
      </div>
    </div>
    }
  </mat-drawer>

  <mat-drawer-content>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
