import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import Swal from "sweetalert2";
import { DateTime } from "luxon";
import { statusMarital } from "../../../shared/enums/statusMarital.enums";
import {
  MatDatepickerIntl,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { IUser } from "../../../models/user/IUser";
import {
  IPersonnel,
  IRole,
} from "../../../models/interfaces/IPersonnel.interface";
import { UserService } from "../../../services/user/user.service";
import { MatIcon } from "@angular/material/icon";
import { JobService } from "../../../services/job/job.service";
import { Router } from "@angular/router";
import { IJob } from "../../../models/interfaces/IJob.interface";
import { RoleService } from "../../../services/role/role.service";

@Component({
  selector: "app-personel-add",
  standalone: true,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    MatDatepickerModule,
    provideNgxMask(),
  ],
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatIcon,
  ],
  templateUrl: "./personel-add.component.html",
  styleUrl: "./personel-add.component.scss",
})
export class PersonelAddComponent implements OnInit {
  statusMaritalList = statusMarital;
  jobList: IJob[] | any = [];
  roleList: IRole[] | any = [];
  lastRoleId = 7;
  infoPersFormGroup = this._formBuilder.group({
    lastName: ["", Validators.required],
    firstName: ["", Validators.required],
    dateOfBirth: [""],
    child: [0],
    statusMarital: [""], //état civil
    contactPhone: [""],
  });
  infoProFormGroup = this._formBuilder.group({
    job_id: ["", Validators.required],
    hiringDate: [],
    email: ["", Validators.required],
    ostie: [""],
    cnaps: [""],
    matricule: [""],
  });
  accessFormGroup = this._formBuilder.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
    role: [0, Validators.required],
  });
  isLinear = false;

  constructor(
    private userService: UserService,
    private jobService: JobService,
    private roleService: RoleService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _adapter: DateAdapter<any>,
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {}

  ngOnInit(): void {
    this.french_lang();
    this.jobService.getAllJobs().subscribe(allJobs => {
      this.jobList = allJobs;
    });
    this.roleService.getallRoleListe().subscribe(allrole => {
      this.roleList = allrole;
      this.lastRoleId = this.roleList[this.roleList.length - 1].id;
      this.accessFormGroup.controls.role.setValue(this.lastRoleId);
    });
  }

  isAllFormsValid() {
    return (
      !!this.infoPersFormGroup.valid &&
      !!this.infoProFormGroup.valid &&
      !!this.accessFormGroup.valid
    );
  }

  french_lang() {
    this._locale = "fr";
    this._adapter.setLocale(this._locale);
    this._intl.closeCalendarLabel = "Fermer le calendrier";
    this._intl.changes.next();
  }

  submitAllforms() {
    if (this.isAllFormsValid()) {
      let persInfo = this.infoPersFormGroup.value;
      let proInfo = this.infoProFormGroup.value;
      let accessright = this.accessFormGroup.value;

      const persUser: IUser = Object.assign(proInfo);
      const userRole: IRole = {
        rolename: "Comptable",
      };
      persUser.role = userRole;
      const newPers: IPersonnel = Object.assign(persInfo);
      newPers.leaveBalance = 0;

      newPers.user = persUser;
      newPers.user.role_id =
        this.accessFormGroup.controls.role.value ?? this.lastRoleId;
      Object.assign(persUser, accessright);

      console.log("New pers  ", newPers);

      this.userService.saveNewPersonnel(newPers).subscribe(res => {
        Swal.fire({
          title: "Success!",
          text: "Utilisateur Crée avec succès",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(response => {
          if (response.isConfirmed) {
            this.router.navigate(["/personnel"]);
          }
        });
      });

      return;
    } else {
      return false;
    }
  }
}
