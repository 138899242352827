import { Component } from "@angular/core";
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { UploadFileComponent } from "../../../shared/components/upload-file/upload-file.component";

@Component({
  selector: "app-personel-add-multiple",
  standalone: true,
  imports: [ButtonComponent, UploadFileComponent],
  templateUrl: "./personel-add-multiple.component.html",
  styleUrl: "./personel-add-multiple.component.scss",
})
export class PersonelAddMultipleComponent {
  acceptedType: string[] = [".csv"];
  title: string = "Import multiple de personnels";

  onFileSelectedHandler(event: any) {
    console.log("huhu", event);
  }
}
