<button
  (click)="onClick()"
  [ngClass]="type"
  [disabled]="disable"
  *ngIf="isVisible">
  <span>
    <mat-icon *ngIf="iconPath" class="material-icons-sharp">{{
      iconPath
    }}</mat-icon>
    {{ label }}
  </span>
</button>
