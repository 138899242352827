<div class="main-content">
  <app-header></app-header>
  <!-- <h2>Bonjour <span>Hary</span></h2> -->

  <section class="card-layout">
    <div class="card-wrapper">
      <app-db-card
        class="card-item"
        bgColor="bgBlue"
        [value]="stockCard.value"
        [label]="stockCard.label"
        [iconPath]="stockCard.icon"></app-db-card>
    </div>
    <div class="card-wrapper">
      <app-db-card
        class="card-item"
        bgColor="bgRed"
        [value]="personelCard.value"
        [label]="personelCard.label"
        [iconPath]="personelCard.icon"
        (moreButtonHandler)="goTo('personnel')"></app-db-card>
    </div>
    <div class="card-wrapper">
      <app-db-card
        class="card-item"
        bgColor="bgGreen"
        [value]="CACard.value"
        [label]="CACard.label"
        [iconPath]="CACard.icon"></app-db-card>
    </div>
  </section>
</div>
