import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
export type BackgroundColor = "bgBlue" | "bgRed" | "bgGreen" | "bgDefault";
@Component({
  selector: "app-db-card",
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatIconModule],
  templateUrl: "./db-card.component.html",
  styleUrl: "./db-card.component.scss",
})
export class DbCardComponent {
  @Input() iconPath: string = "";
  @Input() value: string = "";
  @Input() label: string = "";
  @Input() bgColor: BackgroundColor = "bgDefault";
  @Output() moreButtonHandler: EventEmitter<any> = new EventEmitter<any>();

  click() {
    this.moreButtonHandler.emit();
  }
}
