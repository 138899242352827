import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  _apiUrl = environment._apiURL + "/role";
  constructor(private http: HttpClient) {}

  getallRoleListe() {
    return this.http.get(this._apiUrl);
  }
}
