import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-table-actions-buttons",
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: "./table-actions-buttons.component.html",
  styleUrl: "./table-actions-buttons.component.scss",
})
export class TableActionsButtonsComponent {
  @Input() element: any;
  @Output() editHandler = new EventEmitter<any>();
  @Output() deleteHandler = new EventEmitter<any>();

  handleEdit(event: any) {
    this.editHandler.emit(event);
  }
  handleDelete(event: any) {
    this.deleteHandler.emit(event);
  }
}
