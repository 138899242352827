import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ICategory } from "../../models/interfaces/ICategory.interface";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  _apiUrl = environment._apiURL + "/category";
  constructor(private http: HttpClient) {}

  getallCategoryListe() {
    return this.http.get(this._apiUrl);
  }
  
   createCategory(categoryToCreate: ICategory) {
     return this.http.post(this._apiUrl, categoryToCreate);
   }

  updateCategory(id: string,category: ICategory){
    return this.http.patch(`${this._apiUrl}/${id}`, category);
  }
  
  deleteCategory(id: string){
    return this.http.delete(`${this._apiUrl}/${id}`);
  }

}
