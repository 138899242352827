import { ApplicationConfig } from "@angular/core";
import { provideRouter } from "@angular/router";

import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { routes } from "./app.routes";
import { authenticationInterceptor } from "./services/interceptor/authentication/authentication.interceptor";
import { loadingInterceptor } from "./services/interceptor/loading/loading.interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([authenticationInterceptor, loadingInterceptor])
    ),
    provideClientHydration(),
  ],
};
