<div class="actions-btn">
  <div class="btn-add-sections">
    <app-button
      label="Nouveau"
      iconPath="add-circle"
      type="primary"
      [isVisible]="isAddBtnVisible"
      (clickEvent)="handleAddClick()"></app-button>
    <app-button
      label="Importer"
      iconPath="file_upload"
      type="default"
      [isVisible]="isAddBtnVisible"
      (clickEvent)="handleUploadClick()"></app-button>
  </div>

  <app-input
    type="text"
    [placeholder]="'Rechercher...'"
    [(ngModel)]="searchText"
    (ngModelChange)="applyFilter($event)"
    [color]="'primary'"></app-input>
</div>

@if(dataType =='personnel'){
<app-table-personnel-data
  (editHandlerFn)="handleEdit($event)"
  (deleteHandlerFn)="handleDelete($event)"></app-table-personnel-data>
} @if(dataType =='departement'){
<app-table-departement-data
  (editHandlerFn)="handleEdit($event)"
  (deleteHandlerFn)="handleDelete($event)"></app-table-departement-data>
} @if(dataType =='fonction'){
<app-table-job-data
  (editHandlerFn)="handleEdit($event)"
  (deleteHandlerFn)="handleDelete($event)"></app-table-job-data>
} @if(dataType =='role'){
<app-table-role-data
  (editHandlerFn)="handleEdit($event)"
  (deleteHandlerFn)="handleDelete($event)"></app-table-role-data>
} @if(dataType =='category'){
  <app-table-category-data
    (editHandlerFn)="handleEdit($event)"
    (deleteHandlerFn)="handleDelete($event)"></app-table-category-data>
}
