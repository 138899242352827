import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { LoaderService } from "../../loader/loader.service";
import { finalize } from "rxjs";

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const load = inject(LoaderService);
  load.loadingOn();
  return next(req).pipe(
    finalize(() => {
      load.loadingOff();
    })
  );
};
