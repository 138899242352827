import { Component } from "@angular/core";
import { DbCardComponent } from "../../shared/components/db-card/db-card.component";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { SidebarComponent } from "../../shared/components/sidebar/sidebar.component";

import { Router } from "@angular/router";
import { LoaderComponent } from "../../shared/components/loader/loader.component";
import { CA_CARD, PERSONNEL_CARD, STOCK_CARD } from "./cards/card.item";

@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    SidebarComponent,
    DbCardComponent,
    HeaderComponent,
    LoaderComponent,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent {
  isLoading = false;
  stockCard = STOCK_CARD;
  personelCard = PERSONNEL_CARD;
  CACard = CA_CARD;
  constructor(private router: Router) {}

  goTo(path: string) {
    this.router.navigate([path]);
  }
}
