import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort, MatSortModule, Sort } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { IPersonnel } from "../../../../../models/interfaces/IPersonnel.interface";
import { UserService } from "../../../../../services/user/user.service";
import { TableActionsButtonsComponent } from "../table-actions-buttons/table-actions-buttons.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
@Component({
  selector: "app-table-personnel-data",
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    TableActionsButtonsComponent,
    MatSortModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: "./table-personnel-data.component.html",
  styleUrl: "./table-personnel-data.component.scss",
})
export class TablePersonnelDataComponent implements AfterViewInit, OnInit {
  displayedColumns = [
    "userId",
    "lastName",
    "firstName",
    "contactPhone",
    "isActive",
  ];
  dataSourceMat!: MatTableDataSource<IPersonnel>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: IPersonnel[] = [];

  @Output() editHandlerFn = new EventEmitter<any>();
  @Output() deleteHandlerFn = new EventEmitter<any>();

  constructor(private userService: UserService) {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.userService.getUsers().subscribe(data => {
      this.dataSource = data;
      if (this.dataSource.length > 0) {
        this.dataSourceMat = new MatTableDataSource(this.dataSource);
        this.dataSourceMat.paginator = this.paginator;
        this.dataSourceMat.sort = this.sort;
        this.dataSourceMat.sort.sort({
          id: "userId",
          start: "desc",
          disableClear: false,
        });

        if (
          !this.displayedColumns.includes("actions") &&
          this.displayedColumns.length > 0
        ) {
          this.displayedColumns.push("actions");
        }

        this.dataSourceMat.data = this.dataSource.map(data => {
          return { ...data, actions: null };
        });
      }
    });
  }

  editHandler(event: any) {
    this.editHandlerFn.emit(event);
  }
  deleteHandler(event: any) {
    this.deleteHandlerFn.emit(event);
  }
}
