import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import Swal from "sweetalert2";
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { IUser } from "../../../models/user/IUser";
import { CamelCasePipe } from "../../../pipe/camel-case.pipe";
import { AuthService } from "../../../services/auth/auth.service";
import { ModalService } from "../../../services/modal/modal.service";
import { IConfirmModal } from "../../../models/interfaces/IConfirmModal.interface";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    CamelCasePipe,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    CamelCasePipe,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
  ],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent implements OnInit {
  notifCounter: number = 0;
  currentUser!: IUser;

  constructor(
    private authService: AuthService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    const user = sessionStorage.getItem("currentUser");
    if (user !== null) {
      this.currentUser = JSON.parse(user);

      // console.log(this.currentUser);
    }
  }

  logoutConfirm() {
    const iconf: IConfirmModal = {
      text: "Etes-vous sûr de vouloir vous déconnecter?",
      confirmButtonText: "Oui",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmedFn: () => {
        this.authService.logout().subscribe(() => {});
      },
    };
    this.modalService.confirmModal(iconf);
  }
}
