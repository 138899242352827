<div class="main-container">
  <div class="content-container" [ngClass]="{ 'mobile-section': isMobile }">
    @if (!isMobile) {
    <div class="text-container">
      <div class="text-content">
        <div class="bloc-title">
          <img src="/assets/img/logo.png" alt="logo" width="100%" />
        </div>
        <div class="main-content">
          <p></p>
        </div>
      </div>
    </div>
    } @if (isMobile) {<img
      class="headerlogomobile"
      src="/assets/img/logo.png"
      width="100%"
      alt="logo" />}
    <div
      class="bloc-login-container"
      [ngClass]="{ 'mobile-section': isMobile }">
      <div class="bloc-login">
        <div class="title">S'authentifier</div>
        <div class="login-form">
          <form
            [formGroup]="loginAuthGroup"
            (submit)="submitAuthForm()"
            autocomplete="new-password">
            <div class="input-bloc">
              <label for="" class="label-input">Nom d'utilisateur</label>
              <app-input
                type="text"
                name="mangabe-username"
                formControlName="username"></app-input>
            </div>
            <div class="input-bloc">
              <label for="" class="label-input">Mot de passe</label>
              <app-input
                type="password"
                name="mangabe-password"
                formControlName="password"></app-input>
            </div>
            <div class="btn-action">
              <button mat-button type="submit" class="submit-btn">
                Se connecter
              </button>
            </div>
            <div class="social-network">
              <img src="/assets/icons/rs/social-facebook.svg" />
              <img src="/assets/icons/rs/social-instagram.svg" />
              <img src="/assets/icons/rs/social-twitter.svg" />
              <img src="/assets/icons/rs/social-whats-app.svg" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
