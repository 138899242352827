<mat-stepper [linear]="true" orientation="vertical" #stepper>
  <mat-step [stepControl]="jobFormGroup">
    <ng-template matStepLabel>Nom du poste</ng-template>
    <form [formGroup]="jobFormGroup">
      <div>
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput placeholder="Nom" formControlName="jobName" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Departement</mat-label>
          <mat-select formControlName="departementId">
            @for (item of departmentList; track item) {
            <mat-option [value]="item.id">{{ item.departmentName }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <app-button
          label="Valider"
          type="primary"
          (clickEvent)="submit()"
          [disable]="!isFormValid()"></app-button>
      </div>
    </form>
  </mat-step>
</mat-stepper>
