import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { TablePaginateComponent } from "../../shared/components/table-paginate/table-paginate.component";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatDialog } from "@angular/material/dialog";
import { CategoryEditComponent } from "./category-edit/category-edit.component";

@Component({
  selector: 'app-category',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatRippleModule,
    HeaderComponent,
    TablePaginateComponent,
    RouterOutlet,
  ],
  templateUrl: './category.component.html',
  styleUrl: './category.component.scss'
})
export class CategoryComponent {
  constructor(private route: Router,private dialog: MatDialog) {
    
  }

  handleAdd() {
    this.route.navigate(["/category/add"]);
  }
  handleUpload() {
    console.log("upload");
  }

  handleEdit(item: any): void {
    const dialogRef = this.dialog.open(CategoryEditComponent, {
      width: '50%',
      maxWidth: '90vw', 
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('La popup a été fermée');
    });
  }

  
  handleDelete(item: any) {
    console.log("delete", item);
  }
  

  isTableVisible() {
    return this.route.url.endsWith("/category");
  }

}
