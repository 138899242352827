import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { Router, RouterOutlet } from "@angular/router";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { TablePaginateComponent } from "../../shared/components/table-paginate/table-paginate.component";
import { StockService } from "../../services/stock/stock.service";

@Component({
  selector: "app-stock",
  standalone: true,
  providers: [StockService],
  imports: [
    CommonModule,
    HeaderComponent,
    MatTableModule,
    TablePaginateComponent,
    HttpClientModule,
    RouterOutlet,
  ],
  templateUrl: "./stock.component.html",
  styleUrl: "./stock.component.scss",
})
export class StockComponent implements OnInit {
  displayedColumns: string[] = [];
  dataSource: any[] = [];

  constructor(private stockService: StockService, private route: Router) {}

  ngOnInit(): void {
    this.stockService.getDatas().subscribe(data => {
      this.dataSource = data;
      if (this.dataSource.length > 0) {
        this.displayedColumns = Object.keys(this.dataSource[0]);
      }
    });
  }

  handleAdd() {
    this.route.navigate(["/personnel/add"]);
  }
  handleUpload() {
    this.route.navigate(["/personnel/add/multiple"]);
  }
  handleEdit(item: any) {
    console.log("edit", item);
  }
  handleDelete(item: any) {
    console.log("delete", item);
  }

  isTableVisible() {
    return (
      this.route.url.endsWith("/stock") &&
      this.displayedColumns.length > 0 &&
      this.dataSource.length > 0
    );
  }
}
