import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { JobService } from "../../../../../services/job/job.service";
import { TableActionsButtonsComponent } from "../table-actions-buttons/table-actions-buttons.component";

@Component({
  selector: "app-table-job-data",
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    TableActionsButtonsComponent,
    MatSortModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: "./table-job-data.component.html",
  styleUrl: "./table-job-data.component.scss",
})
export class TableJobDataComponent {
  displayedColumns: any[] = ["id", "jobName", "isActive"];
  dataSourceMat!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: any;

  @Output() editHandlerFn = new EventEmitter<any>();
  @Output() deleteHandlerFn = new EventEmitter<any>();

  constructor(private jobService: JobService) {}

  ngAfterViewInit(): void {
    this.jobService.getAllJobs().subscribe(data => {
      this.dataSource = data;
      if (this.dataSource.length > 0 && !!data) {
        this.dataSourceMat = new MatTableDataSource(this.dataSource);
        this.dataSourceMat.paginator = this.paginator;
        this.dataSourceMat.sort = this.sort;
        this.dataSourceMat.sort.sort({
          id: "id",
          start: "desc",
          disableClear: false,
        });

        if (
          !this.displayedColumns.includes("actions") &&
          this.displayedColumns.length > 0
        ) {
          this.displayedColumns.push("actions");
        }

        this.dataSourceMat.data = this.dataSource.map((data: any) => {
          return { ...data, actions: null };
        });
      }
    });
  }

  editHandler(event: any) {
    this.editHandlerFn.emit(event);
  }
  deleteHandler(event: any) {
    this.deleteHandlerFn.emit(event);
  }
}
