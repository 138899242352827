import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private showLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {}

  getLoadComponentState(): Observable<boolean> {
    return this.showLoader.asObservable();
  }

  loadingOn() {
    this.showLoader.next(true);
  }
  loadingOff() {
    this.showLoader.next(false);
  }
}
