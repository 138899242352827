import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-menu-item",
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: "./menu-item.component.html",
  styleUrl: "./menu-item.component.scss",
})
export class MenuItemComponent {
  @Input() title!: string;
  @Input() iconPath: string = "";
  @Input() route: string = "";
  @Input() isActive: boolean = false;
  @Input() iconOnly: boolean = false;
  @Output() onMenuClick = new EventEmitter<any>();

  onClick(route: string) {
    this.onMenuClick.emit(route);
  }
}
