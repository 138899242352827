<mat-stepper [linear]="true" orientation="vertical" #stepper>
  <mat-step [stepControl]="categoryFormGroup">
    <ng-template matStepLabel>Nouvelle catégorie</ng-template>
    <form [formGroup]="categoryFormGroup">

      <div class="container">
        <div class="card">
          <h3>{{ title }}</h3>
          <div class="drop_box">
            <!-- Vérifier si un fichier est sélectionné -->
            <ng-container *ngIf="selectedFile; else noFile">
              <mat-list>
                <mat-list-item>
                  <mat-icon matListItemIcon class="material-icons-sharp">file_present</mat-icon>
                  <h3 matListItemLine>
                    {{ selectedFile.name }} ({{ getFileSize(selectedFile.size) }})
                    <button mat-icon-button matListItemLine (click)="removeFile()">
                      <mat-icon class="material-icons-sharp">close</mat-icon>
                    </button>
                  </h3>
                </mat-list-item>
              </mat-list>
            </ng-container>
      
            <ng-template #noFile>
              <header>
                <h4>Sélectionner un fichier ici</h4>
              </header>
              <p>Fichiers supportés: {{ supportedType }}</p>
            </ng-template>
      
            <input
              formControlName="image"
              type="file"
              hidden
              [accept]="supportedType"
              id="fileID"
              #fileID
              (change)="onfileSelectedHandler($event)" />
              
            <app-button
              [label]="btnLabel"
              type="primary"
              (clickEvent)="fileID.click()"></app-button>
          </div>
        </div>
      </div>

      
      <div class="form-field">
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput placeholder="Nom" formControlName="name" />
        </mat-form-field>
      </div><div class="form-field">
        <mat-form-field>
        <mat-label>Note</mat-label>
        <input matInput placeholder="Note" formControlName="note" />
        </mat-form-field>
    </div>
    <div class="form-field">
      <label>Status: </label>
        <mat-slide-toggle formControlName="isActive" 
        (change)="toggleStatus()">
          <span>{{ status ? 'Actif' : 'Inactif' }}</span>
        </mat-slide-toggle>
    </div>
      
      <div class="form-field">
        <app-button
          label="Valider"
          type="primary"
          (clickEvent)="submit()"
          [disable]="!isFormValid()"></app-button>
      </div>
    </form>
  </mat-step>
</mat-stepper>
