import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StockService {
  private base_url = "assets/data/fakeStockData.json";
  constructor(private http: HttpClient) {}

  getDatas(): Observable<any[]> {
    return this.http.get<any[]>(this.base_url);
  }
}
