import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatError } from "@angular/material/form-field";

@Component({
  selector: "app-input",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatError],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  templateUrl: "./input.component.html",
  styleUrl: "./input.component.scss",
})
export class InputComponent implements ControlValueAccessor {
  @Input() type!: string;
  @Input() placeholder: string = "";
  @Input() color: string = "default";
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  value: string = "";

  formControl: FormControl = new FormControl();

  constructor() {}

  searchTextChange() {
    this.search.emit(this.formControl.value);
  }

  writeValue(newvalue: any): void {
    this.formControl.setValue(newvalue);
    this.value = newvalue;
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  onInputChange(event: any) {
    this.writeValue(event.target.value);
  }
}
