import { Routes } from "@angular/router";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { Error404Component } from "./pages/error404/error404.component";
import { LoginComponent } from "./pages/login/login.component";
import { PersonelAddMultipleComponent } from "./pages/personel/personel-add-multiple/personel-add-multiple.component";
import { PersonelAddComponent } from "./pages/personel/personel-add/personel-add.component";
import { PersonelComponent } from "./pages/personel/personel.component";
import { ProductComponent } from "./pages/product/product.component";
import { StockComponent } from "./pages/stock/stock.component";
import { SupplierComponent } from "./pages/supplier/supplier.component";
import { authenticationGuard } from "./services/auth/guards/authentication.guard";
import { SettingsComponent } from "./pages/settings/settings.component";
import { DepartementComponent } from "./pages/departement/departement.component";
import { DepartementAddComponent } from "./pages/departement/departement-add/departement-add.component";
import { JobComponent } from "./pages/job/job.component";
import { JobAddComponent } from "./pages/job/job-add/job-add.component";
import { RoleComponent } from "./pages/role/role.component";
import { CategoryComponent } from "./pages/category/category.component";
import { CategoryAddComponent } from "./pages/category/category-add/category-add.component";

export const routes: Routes = [
  {
    path: "",
    loadComponent: () => LoginComponent,
  },
  {
    path: "login",
    loadComponent: () => LoginComponent,
  },
  {
    path: "dashboard",
    loadComponent: () => DashboardComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "dashboard",
      },
    },
  },
  {
    path: "personnel",
    loadComponent: () => PersonelComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "assignment_ind",
      },
    },
    loadChildren: () => [
      {
        path: "add",
        loadComponent: () => PersonelAddComponent,
        canActivate: [authenticationGuard],
        data: { breadcrumb: "Nouveau" },
      },
      {
        path: "add/multiple",
        loadComponent: () => PersonelAddMultipleComponent,
        canActivate: [authenticationGuard],
        data: { breadcrumb: "Importation-Personnel" },
      },
    ],
  },

  {
    path: "stock",
    loadComponent: () => StockComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "insert_chart_outlined",
      },
    },
  },
  {
    path: "produit",
    loadComponent: () => ProductComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: "fournisseur",
    loadComponent: () => SupplierComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: "settings",
    loadComponent: () => SettingsComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "settings",
      },
    },
  },
  {
    path: "role",
    loadComponent: () => RoleComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "verified_user",
      },
    },
  },
  {
    path: "departement",
    loadComponent: () => DepartementComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "home_work",
      },
    },
    loadChildren: () => [
      {
        path: "add",
        loadComponent: () => DepartementAddComponent,
        canActivate: [authenticationGuard],
        data: { breadcrumb: "Nouveau" },
      },
    ],
  },
  {
    path: "fonction",
    loadComponent: () => JobComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "home_work",
      },
    },
    loadChildren: () => [
      {
        path: "add",
        loadComponent: () => JobAddComponent,
        canActivate: [authenticationGuard],
        data: { breadcrumb: "Nouveau" },
      },
    ],
  },
  
  {
    path: "category",
    loadComponent: () => CategoryComponent,
    canActivate: [authenticationGuard],
    data: {
      breadcrumb: {
        info: "ballot",
      },
    },
    loadChildren: () => [
      {
        path: "add",
        loadComponent: () => CategoryAddComponent,
        canActivate: [authenticationGuard],
        data: { breadcrumb: "Nouveau" },
      },
    ],
  },

  {
    path: "error",
    loadComponent: () => Error404Component,
    canActivate: [authenticationGuard],
  },

  {
    path: "**",
    redirectTo: "/error",
  },
  
];
