<h2 mat-dialog-title>Modifier la catégorie</h2>
<mat-dialog-content>
    <form [formGroup]="editCategoryForm">
        <div class="content">
        <div class="container">
            <h3>{{ title }}</h3>
            <div class="drop_box">
              <ng-container *ngIf="selectedFile; else noFile">
                <mat-list>
                  <mat-list-item>
                    <mat-icon matListItemIcon class="material-icons-sharp">file_present</mat-icon>
                    <h3 matListItemLine>
                      {{ selectedFile.name }} ({{ getFileSize(selectedFile.size) }})
                      <button mat-icon-button matListItemLine (click)="removeFile()">
                        <mat-icon class="material-icons-sharp">close</mat-icon>
                      </button>
                    </h3>
                  </mat-list-item>
                </mat-list>
              </ng-container>
              <ng-template #noFile>
                <img [src]="imagePreview" alt="Image de la catégorie" *ngIf="imagePreview" class="category-image" />
              </ng-template>
              
              <div style="display: flex;justify-content: center;">
              <input
                formControlName="image"
                type="file"
                hidden
                [accept]="supportedType"
                id="fileID"
                #fileID
                (change)="onfileSelectedHandler($event)" />
                  <app-button
                [label]="btnLabel"
                type="primary"
                (clickEvent)="fileID.click()"></app-button>
                </div>
            </div>
          
    </div>
  
        <div>
            <div class="form-field">
                <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput placeholder="Nom" formControlName="name" />
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field>
                <mat-label>Note</mat-label>
                <input matInput placeholder="Note" formControlName="note" />
                </mat-form-field>
            </div>
            <div class="form-field">
              <label>Status: </label>
                <mat-slide-toggle formControlName="isActive" 
                (change)="toggleStatus()">
                  <span>{{ status ? 'Actif' : 'Inactif' }}</span>
                </mat-slide-toggle>
            </div>
        </div>
    </div>

        
        
        <!-- Bouton de validation -->
        <div class="dialog-actions">
            <app-button
              label="Annuler"
              type="default"
              (clickEvent)="onClose()"
              ></app-button>
          <app-button
            label="Modifier"
            type="primary"
            (clickEvent)="submit()"
            [disable]="!isFormValid()"></app-button>
        </div>
      </form>
  
</mat-dialog-content>

