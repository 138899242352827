<section>
  <mat-stepper [linear]="isLinear" orientation="vertical" #stepper>
    <mat-step [stepControl]="infoPersFormGroup">
      <ng-template matStepLabel>Info Personnelles</ng-template>
      <form [formGroup]="infoPersFormGroup">
        <div>
          <mat-form-field>
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Nom" formControlName="lastName" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Prenom</mat-label>
            <input matInput placeholder="Prenom" formControlName="firstName" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Date d'anniversaire</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="dateOfBirth" />
            <mat-hint>JJ/MM/AAAA</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Nombre d'enfant(s)</mat-label>
            <input
              type="number"
              matInput
              placeholder="Nbr Enfant"
              formControlName="child" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Etat Civil</mat-label>
            <mat-select formControlName="statusMarital">
              @for (status of statusMaritalList; track status) {
              <mat-option [value]="status.value">{{ status.label }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Contact</mat-label>
            <input
              matInput
              placeholder="xx xx xxx xx"
              formControlName="contactPhone" />
            <span matTextSuffix></span>
            <mat-hint>Format :+261 XX XX XXX XX</mat-hint>
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperNext class="hiddenStepper">
            <app-button label="Suivant" type="primary"></app-button>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="infoProFormGroup">
      <ng-template matStepLabel>Info Professionnelles</ng-template>
      <form [formGroup]="infoProFormGroup">
        <div>
          <mat-form-field>
            <mat-label>Fonctions</mat-label>
            <mat-select formControlName="job_id">
              @for (job of jobList; track job) {
              <mat-option [value]="job.id">{{ job.jobName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Date d'embauche </mat-label>
            <input
              matInput
              [matDatepicker]="hiringDatePicker"
              formControlName="hiringDate"
              placeholder="Date d'embauche" />
            <mat-hint>JJ/MM/AAAA</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="hiringDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #hiringDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Email professionel</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              placeholder="Email"
              autocomplete="new-password" />
            @if (infoProFormGroup.controls.email.hasError('required') ||
            infoProFormGroup.controls.email.hasError('email')) {
            <mat-error>Entrer une adresse email valide</mat-error>
            }
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Cnaps</mat-label>
            <input matInput placeholder="Cnaps" formControlName="cnaps" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>OSTIE</mat-label>
            <input matInput placeholder="Ostie" formControlName="ostie" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>N° Matricule</mat-label>
            <input
              matInput
              placeholder="Matricule"
              formControlName="matricule" />
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperPrevious class="hiddenStepper">
            <app-button label="Précédent" type="default"></app-button>
          </button>
          <button mat-button matStepperNext class="hiddenStepper">
            <app-button label="Suivant" type="primary"></app-button>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="accessFormGroup">
      <ng-template matStepLabel>Gestion des Accès</ng-template>
      <form [formGroup]="accessFormGroup">
        <div>
          <mat-form-field>
            <mat-label>Nom d'utilisateur</mat-label>
            <input
              autocomplete="new-password"
              type="text"
              matInput
              formControlName="username"
              placeholder="Nom d'utilisateur"
              class="no-autocomplete" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Mot de passe</mat-label>
            <input
              autocomplete="new-password"
              type="password"
              matInput
              formControlName="password"
              placeholder="Mot de passe" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
              @for (role of roleList; track role) {
              <mat-option [value]="role.id">{{ role.rolename }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button mat-button matStepperPrevious class="hiddenStepper">
            <app-button label="Précédent" type="default"></app-button>
          </button>
          <button mat-button matStepperNext class="hiddenStepper">
            <app-button label="Suivant" type="primary"></app-button>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Terminé</ng-template>
      <p>
        Assurez-vous d'avoir ajouter toutes les informations correctes, et
        remplis toutes les champs obligatoires avant de valider.
      </p>
      <div>
        <button mat-button matStepperPrevious class="hiddenStepper">
          <app-button label="Précédent" type="default"></app-button>
        </button>
        <button mat-button class="hiddenStepper">
          <app-button
            label="Réinitialiser"
            type="cancel"
            (clickEvent)="stepper.reset()"></app-button>
        </button>
        <button mat-button class="hiddenStepper">
          <app-button
            label="Valider"
            type="primary"
            (clickEvent)="submitAllforms()"
            [disable]="!isAllFormsValid()"></app-button>
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</section>
