<input
  [ngClass]="color"
  [type]="type"
  [value]="value"
  [placeholder]="placeholder"
  (input)="onInputChange($event)"
  autocomplete="new-password"
  class="form-control" />
@if (type=='email' &&formControl.hasError('email') &&
!formControl.hasError('required')) {
<mat-error>Veuillez ajouter un adresse email valide</mat-error>
} @if ( formControl.touched && !formControl.hasError('required')) {
<mat-error>Ce champ est obligatoire</mat-error>
}
