import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { CamelCasePipe } from "../../../pipe/camel-case.pipe";

import Swal from "sweetalert2";
import { UserService } from "../../../services/user/user.service";
import { ButtonComponent } from "../button/button.component";
import { InputComponent } from "../input/input.component";
import { TableActionsButtonsComponent } from "./components/table-actions-buttons/table-actions-buttons.component";
import { TablePersonnelDataComponent } from "./components/table-personnel-data/table-personnel-data.component";
import { TableDepartementDataComponent } from "./components/table-departement-data/table-departement-data.component";
import { TableJobDataComponent } from "./components/table-job-data/table-job-data.component";
import { TableRoleDataComponent } from "./components/table-role-data/table-role-data.component";
import { TableCategoryDataComponent } from "./components/table-category-data/table-category-data.component";
import { CategoryService } from "../../../services/category/category.service";

@Component({
  selector: "app-table-paginate",
  standalone: true,
  templateUrl: "./table-paginate.component.html",
  styleUrl: "./table-paginate.component.scss",
  imports: [
    CommonModule,
    MatTableModule,
    ButtonComponent,
    MatPaginatorModule,
    CamelCasePipe,
    MatButtonModule,
    FormsModule,
    InputComponent,
    MatCheckboxModule,
    MatIconModule,
    TableActionsButtonsComponent,
    TablePersonnelDataComponent,
    TableDepartementDataComponent,
    TableJobDataComponent,
    TableRoleDataComponent,
    TableCategoryDataComponent,
  ],
})
export class TablePaginateComponent implements OnInit {
  @Input() displayedColumns: string[] = [];
  @Input() dataSource: any[] = [];
  @Input() dataType: string = "personnel";

  /* Actions sur les boutons */
  @Output() addEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() UploadEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  /* ------- Actions sur les boutons --------- */
  isAddBtnVisible = true;
  searchText: string = "";

  constructor(private userService: UserService,private categoryService: CategoryService) {}

  ngOnInit(): void {
    if (this.dataType == "role") {
      this.isAddBtnVisible = false;
    }
  }

  handleAddClick() {
    this.addEvent.emit();
  }
  handleUploadClick() {
    this.UploadEvent.emit();
  }

  handleEdit(element: any) {
    this.editEvent.emit(element);
  }
  handleDelete(element: any) {
    this.deleteEvent.emit(element);
    if (this.dataType == "personnel") {
      Swal.fire({
        title: `Voulez-vous vraiment supprimer ce personnel #${element.userId} ?`,
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      }).then(result => {
        if (result.isConfirmed) {
          this.userService.softDelete(element.id).subscribe(result => {
            if (result) {
              Swal.fire("Saved!", "", "success");
              window.location.reload();
            } else Swal.fire("Changes are not saved", "", "info");
          });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    } else if (this.dataType == "category") {
      Swal.fire({
        title: `Voulez-vous vraiment supprimer cette categorie ?`,
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      }).then(result => {
        if (result.isConfirmed) {
          this.categoryService.deleteCategory(element.id).subscribe(result => {
            Swal.fire({
              title: "Success!",
              text: "Categorie supprimée avec succès",
              icon: "success",
              confirmButtonText: "Ok",
            }).then(response => {
              if (response.isConfirmed) {
                window.location.reload();
              }
            });
          });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  }

  applyFilter(event: any) {
    this.searchText = event.trim().toLowerCase();
    // this.dataSourceMat.filter = this.searchText;
  }
}
