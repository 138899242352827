import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule,MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { UploadfileService } from '../../../shared/services/uploadfile/uploadfile.service';
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { MatSelect } from '@angular/material/select';
import { CategoryService } from '../../../services/category/category.service';
import { ICategory } from '../../../models/interfaces/ICategory.interface';
import { Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
@Component({
  selector: 'app-category-edit',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatListModule,
    MatSelect,
    MatIcon,
    MatInputModule,
    ReactiveFormsModule,
    MatOption,
    ButtonComponent,
    MatSlideToggleModule,
  ],
  templateUrl: './category-edit.component.html',
  styleUrl: './category-edit.component.scss'
})
export class CategoryEditComponent implements OnInit{
  category : ICategory | any;
  imagePreview: string = '';
  status: boolean = false;
  selectedFile: File | undefined ;
  
  editCategoryForm = this._formBuilder.group({
    name: [this.data.name || "", Validators.required],
    image: [null],
    note: [this.data.note || ""],
    isActive: [this.data.isActive],
  });

  @Input() title: string = "";
  @Input() btnLabel: string = "Choisir une image";
  @Input() supportedType: string[] = [".png",".jpg",".jpeg"];
  @Input() multiple: boolean = false;
  @Output() onFileSelected: EventEmitter<File> = new EventEmitter<File>();
  
  constructor(
    private categoryService: CategoryService,
    private uploadService: UploadfileService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CategoryEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.imagePreview = this.data.image;
    this.status = this.data.isActive;
  }

  onfileSelectedHandler(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0]; 
      if (this.uploadService.isSupportedType(file, this.supportedType)) {
        this.selectedFile = file; 
        this.onFileSelected.emit(this.selectedFile); 
      } else {
        Swal.fire({
          title: "Error",
          text: "Type de Fichier non pris en compte. Veuillez réessayer avec un autre type de fichier!",
          icon: "error",
        });
      }
    }
  }
  
  toggleStatus() {
    this.status = !this.status;
    this.editCategoryForm.get('isActive')?.setValue(this.status);
  }
  
  isFormValid() {
    return !!this.editCategoryForm.valid;
  }
  
  getFileSize(size: number): string {
    return this.uploadService.getFileSize(size);
  }
  removeFile() {
    this.selectedFile = undefined; 
  }


  onClose(): void {
    this.dialogRef.close();
  }
  submit() {
    if(this.isFormValid()){
      let categoryInfo = this.editCategoryForm.value;
      const updatedCategory: ICategory = Object.assign(categoryInfo);
      if(!this.selectedFile){
        updatedCategory.image = this.data.image;
      }
      console.log("Updated Category",updatedCategory);

      this.categoryService.updateCategory(this.data.id,updatedCategory).subscribe(res => {
        Swal.fire({
          title: "Success!",
          text: "Categorie modifiée avec succès",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(response => {
          if (response.isConfirmed) {
            this.dialogRef.close(this.editCategoryForm.value);
            this.router.navigate(["/category"]);
            window.location.reload();
          }
        });
      });
    }
  }

}
