import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { CategoryService } from '../../../services/category/category.service';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputComponent } from '../../../shared/components/input/input.component';
import { UploadFileComponent } from '../../../shared/components/upload-file/upload-file.component';
import { UploadfileService } from '../../../shared/services/uploadfile/uploadfile.service';
import Swal from 'sweetalert2';
import { MatListModule } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatOption, MatSelect } from '@angular/material/select';
import { ICategory } from '../../../models/interfaces/ICategory.interface';
import { Router } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-category-add',
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    MatSelect,
    MatOption,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatListModule,
    MatIcon,
    MatInputModule,
    InputComponent,
    UploadFileComponent,
    ButtonComponent,
    MatSlideToggleModule,
  ],
  templateUrl: './category-add.component.html',
  styleUrl: './category-add.component.scss'
})
export class CategoryAddComponent implements OnInit{
  status: boolean = true;
  categoryFormGroup = this._formBuilder.group({
    name: ["", Validators.required],
    image: [null],
    note: [""],
    isActive: [true],
  });


  toggleStatus() {
    this.status = !this.status;
    this.categoryFormGroup.get('isActive')?.setValue(this.status);
  }

  @Input() title: string = "";
  @Input() btnLabel: string = "Choisir une image";
  @Input() supportedType: string[] = [".png",".jpg",".jpeg"];
  @Input() multiple: boolean = false;
  @Output() onFileSelected: EventEmitter<File> = new EventEmitter<File>();
  selectedFile: File | undefined;

  
  constructor(
    private categoryService: CategoryService,
    private uploadService: UploadfileService,
    private _formBuilder: FormBuilder,
    private router: Router,
  ) {}




  onfileSelectedHandler(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0]; 
      if (this.uploadService.isSupportedType(file, this.supportedType)) {
        this.selectedFile = file; 
        this.onFileSelected.emit(this.selectedFile); 
      } else {
        Swal.fire({
          title: "Error",
          text: "Type de Fichier non pris en compte. Veuillez réessayer avec un autre type de fichier!",
          icon: "error",
        });
      }
    }
  }
  
  getFileSize(size: number): string {
    return this.uploadService.getFileSize(size);
  }
  removeFile() {
    this.selectedFile = undefined;
  }
  

  ngOnInit(): void {
    
  }

  isFormValid() {
    return !!this.categoryFormGroup.valid;
  }

  submit() {
    if(this.isFormValid()){
      let categoryInfo = this.categoryFormGroup.value;
      const newCategory: ICategory = Object.assign(categoryInfo);

      this.categoryService.createCategory(newCategory).subscribe(res => {
        Swal.fire({
          title: "Success!",
          text: "Categorie Crée avec succès",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(response => {
          if (response.isConfirmed) {
            this.router.navigate(["/category"]);
          }
        });
      });
    }
  }
}
