import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { IJob } from "../../models/interfaces/IJob.interface";

@Injectable({
  providedIn: "root",
})
export class JobService {
  _apiUrl = environment._apiURL + "/job";
  constructor(private http: HttpClient) {}

  getAllJobs() {
    return this.http.get(this._apiUrl);
  }

  createJob(jobToCreate: IJob) {
    return this.http.post(this._apiUrl, jobToCreate);
  }
}
