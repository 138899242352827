export const MENU_LIST = [
  {
    title: "Tableau de bord",
    iconPath: "dashboard",
    route: "/dashboard",
    isActive: true,
  },
  {
    title: "Stock",
    iconPath: "insert_chart_outlined",
    route: "/stock",
    isActive: false,
  },
  {
    title: "Personnel",
    iconPath: "assignment_ind",
    route: "/personnel",
    isActive: false,
  },
  {
    title: "Commandes",
    iconPath: "checklist",
    route: "/commandes",
    isActive: false,
  },
  {
    title: "Fournisseurs",
    iconPath: "assignment",
    route: "/fournisseurs/",
    isActive: false,
  },
];

export const ACTION_LIST = [
  {
    title: "Paramètres",
    iconPath: "settings",
    route: "/settings",
  },
  {
    title: "Déconnexion",
    iconPath: "logout",
    route: "/logout",
  },
];
