import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import { ACTION_LIST, MENU_LIST } from "./menu/menu.list";
import Swal from "sweetalert2";
import { AuthService } from "../../../services/auth/auth.service";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [MenuItemComponent, CommonModule, MatSidenavModule],
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
  animations: [],
})
export class SidebarComponent implements OnInit {
  isSidebarOpened = true;
  iconOnly = false;
  MenuList = MENU_LIST;
  actionList = ACTION_LIST;

  constructor(
    private router: Router,
    private responsive: BreakpointObserver,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.responsive
      .observe([
        Breakpoints.Handset,
        Breakpoints.XSmall,
        Breakpoints.Medium,
        Breakpoints.Tablet,
      ])
      .subscribe(result => {
        this.isSidebarOpened = !result.matches; // Fermer la barre latérale sur mobile
      });
  }
  updateActiveMenus(currentUrl: string): void {
    this.MenuList.forEach(menu => {
      menu.isActive = currentUrl.startsWith(menu.route);
    });
  }

  redirectTo(route: string) {
    this.MenuList.forEach(menu => {
      menu.isActive = menu.route === route;
    });
    if (route.startsWith("/logout")) {
      this.logoutConfirm();
    } else this.router.navigate([route]);
  }

  toggleSidebar() {
    this.isSidebarOpened = !this.isSidebarOpened;
    this.iconOnly = !this.iconOnly;
  }

  logoutConfirm() {
    Swal.fire({
      title: "Confirmation",
      text: "Etes-vous sûr de vouloir vous déconnecter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
    }).then(result => {
      if (result.isConfirmed) {
        this.authService.logout().subscribe();
      }
    });
  }
}
