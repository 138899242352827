<div class="header-section">
  <section class="header-container">
    <div class="text-layout">Bienvenue chez Mangabe</div>
    <div class="action-layout">
      <div class="button-layout">
        <div class="button-container">
          <button mat-icon-button>
            <mat-icon class="material-icons-sharp">search</mat-icon>
          </button>
        </div>
        <div class="button-container">
          <button mat-icon-button>
            <mat-icon
              aria-hidden="false"
              [matBadge]="notifCounter"
              [matBadgeHidden]="!!!notifCounter"
              class="material-icons-sharp"
              >notifications_none</mat-icon
            >
          </button>
        </div>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" class="btn-menu-profil">
        <div class="pdp-layout">
          <div class="profile-layout">
            <img
              height="60"
              src="https://img.icons8.com/color/48/user-male-circle--v1.png"
              alt="user-male-circle--v1" />
          </div>
          <div class="infoPers-layout">
            <div class="name">{{ currentUser.username | camelCase }}</div>
            <div class="job">Graphic Designer</div>
          </div>
        </div>
      </button>
      <mat-menu #menu="matMenu" class="mat-menu">
        <button mat-menu-item>
          <mat-icon class="material-icons-sharp">assignment_ind</mat-icon>
          <span> Mon profil</span>
        </button>
        <button mat-menu-item (click)="logoutConfirm()">
          <mat-icon class="material-icons-sharp">logout</mat-icon>
          <span> Se Déconnecter</span>
        </button>
      </mat-menu>
    </div>
  </section>
  <div class="breadcrumb-container">
    <xng-breadcrumb>
      <ng-container
        *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
        <mat-icon class="material-icons-sharp">{{ info }}</mat-icon> &nbsp;
        <ng-container>{{ breadcrumb | camelCase }}</ng-container>
      </ng-container>
    </xng-breadcrumb>
  </div>
</div>
