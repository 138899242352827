<mat-stepper [linear]="true" orientation="vertical" #stepper>
  <mat-step [stepControl]="deptFormGroup">
    <ng-template matStepLabel>Info sur le département</ng-template>
    <form [formGroup]="deptFormGroup">
      <div>
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput placeholder="Nom" formControlName="departmentName" />
        </mat-form-field>
      </div>

      <div>
        <app-button
          label="Valider"
          type="primary"
          (clickEvent)="submit()"
          [disable]="!isFormValid()"></app-button>
      </div>
    </form>
  </mat-step>
</mat-stepper>
