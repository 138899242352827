import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ILogin } from "../../models/interfaces/ILogin";
import { AuthService } from "../../services/auth/auth.service";
import { InputComponent } from "../../shared/components/input/input.component";
import { UserService } from "../../services/user/user.service";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSnackBarModule,
  ],
  providers: [AuthService],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit {
  isMobile: boolean = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private responsive: BreakpointObserver,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  loginAuthGroup = this._formBuilder.group({
    // username: ["admin", Validators.required],
    // password: ["admin123", Validators.required],
    username: ["", Validators.required],
    password: ["", Validators.required],
  });

  ngOnInit(): void {
    this.responsive
      .observe([
        Breakpoints.Handset,
        Breakpoints.XSmall,
        Breakpoints.Medium,
        Breakpoints.Tablet,
        Breakpoints.TabletLandscape,
      ])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  submitAuthForm() {
    if (this.loginAuthGroup.invalid) this.loginError();
    const loginData: ILogin = {
      username: this.loginAuthGroup.get("username")!.value ?? "",
      password: this.loginAuthGroup.get("password")!.value ?? "",
    };
    this.authService
      .signin(loginData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: user => {
          this.userService.getUserAccess(user.uuid).subscribe(result => {
            console.log("access", result);
          });
          this.router.navigate(["/dashboard"]);
        },
      });
  }

  loginError() {
    this._snackBar.open(
      "Votre nom d'utilisateur ou votre mot de passe est incorrect. Veuillez réessayer",
      "Fermer",
      {
        duration: 3000,
      }
    );
  }
}
