<div class="container">
  <div class="card">
    <h3>{{ title }}</h3>
    <div class="drop_box">
      @if (selectedFiles.length > 0) {
      <mat-list>
        @for (file of selectedFiles; track file) {
        <mat-list-item>
          <mat-icon matListItemIcon class="material-icons-sharp"
            >file_present</mat-icon
          >
          <h3 matListItemLine>
            {{ file.name }} ({{ getFileSize(file.size) }})
            <button mat-icon-button matListItemLine (click)="removeFile(file)">
              <mat-icon class="material-icons-sharp">close</mat-icon>
            </button>
          </h3>
        </mat-list-item>
        }
      </mat-list>
      } @else {
      <header>
        <h4>Selectionner un fichier ici</h4>
      </header>
      <p>Fichiers supportés: {{ supportedType }}</p>
      }

      <input
        type="file"
        [multiple]="multiple"
        hidden
        [accept]="supportedType"
        id="fileID"
        #fileID
        (change)="fileSelectedHandler($event)" />
      <app-button
        [label]="btnLabel"
        type="primary"
        (clickEvent)="fileID.click()"></app-button>
    </div>
  </div>
</div>
