import { Injectable } from "@angular/core";
import { IConfirmModal } from "../../models/interfaces/IConfirmModal.interface";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor() {}

  confirmModal(modalInfo: IConfirmModal) {
    Swal.fire({
      title: "Confirmation",
      text: modalInfo.text,
      icon: "warning",
      showCancelButton: modalInfo.showCancelButton,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: modalInfo.confirmButtonText ?? "OK",
      cancelButtonText: modalInfo.cancelButtonText ?? "Annuler",
    }).then(result => {
      if (result.isConfirmed && modalInfo.confirmedFn) {
        modalInfo.confirmedFn();
      } else if (!result.isConfirmed && modalInfo.refusedFn) {
        modalInfo.refusedFn();
      }
    });
  }
}
