import { Component, DestroyRef, Inject, OnInit, inject } from "@angular/core";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import {
  MatDatepickerIntl,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { NgxMaskDirective } from "ngx-mask";
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { Router } from "@angular/router";
import { DepartementService } from "../../../services/departement/departement.service";
import { IDepartement } from "../../../models/interfaces/IDepartement.interface";
import Swal from "sweetalert2";
import { JobService } from "../../../services/job/job.service";
import { IJob } from "../../../models/interfaces/IJob.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "app-job-add",
  standalone: true,
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    NgxMaskDirective,
    MatIcon,
  ],
  templateUrl: "./job-add.component.html",
  styleUrl: "./job-add.component.scss",
})
export class JobAddComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  departmentList: IDepartement[] | any = [];
  jobFormGroup = this._formBuilder.group({
    jobName: ["", Validators.required],
    departementId: [0, Validators.required],
  });

  constructor(
    private jobService: JobService,
    private departementService: DepartementService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _adapter: DateAdapter<any>,
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {}

  ngOnInit(): void {
    this.departementService
      .getAllDepartement()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: IDepartement) => {
        this.departmentList = res;
        this.jobFormGroup.controls.departementId.setValue(
          this.departmentList[0].id
        );
      });
  }

  isFormValid() {
    return !!this.jobFormGroup.valid;
  }

  submit() {
    const dept = this.jobFormGroup.value;
    const newJob: IJob = Object.assign(dept);

    this.jobService.createJob(newJob).subscribe(res => {
      Swal.fire({
        title: "Success!",
        text: "Fonction crée avec succès",
        icon: "success",
        confirmButtonText: "Ok",
      }).then(response => {
        if (response.isConfirmed) {
          this.router.navigate(["/fonction"]);
        }
      });
    });
  }
}
