import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IPersonnel } from "../../models/interfaces/IPersonnel.interface";

@Injectable({
  providedIn: "root",
})
export class UserService {
  // private base_url = "assets/data/fakeUserData.json";
  _apiUrl = environment._apiURL + "/personnel";
  _apiUrlUser = environment._apiURL + "/user";
  constructor(private http: HttpClient) {}

  getUsers(): Observable<any[]> {
    return this.http.get<IPersonnel[]>(this._apiUrl);
  }

  getUserAccess(uuid: string) {
    return this.http.get(this._apiUrlUser + "/access/" + uuid);
  }

  saveNewPersonnel(personnelToInsert: IPersonnel) {
    return this.http.post(this._apiUrl, personnelToInsert);
  }

  softDelete(id: number) {
    return this.http.delete(this._apiUrl + "/" + id);
  }
}
