export const STOCK_CARD = {
  value: "33.2k",
  label: "Subscribers",
  icon: "dashboard",
};

export const PERSONNEL_CARD = {
  value: "10",
  label: "Personnels",
  icon: "person_pin",
};

export const CA_CARD = {
  value: "15.000Ar",
  label: "Chiffre D'affaire",
  icon: "attach_money",
};
