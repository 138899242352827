<app-sidebar #sidebar *ngIf="showSidebar">
  <ng-container>
    <router-outlet />
  </ng-container>
</app-sidebar>

<ng-container id="content" *ngIf="!showSidebar">
  <router-outlet />
</ng-container>
<app-loader></app-loader>
