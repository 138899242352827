<div class="main-container">
  <div class="content">
    <div class="bloc-img"><span>404</span></div>
    <div class="bloc-text">
      <div class="text-section">
        <span class="title">Oops! !</span>
        <span class="text">La page que vous avez demandé est introuvable</span>
      </div>
      <div class="action-section">
        <a href="/dashboard">< Revenir à la page principale</a>
      </div>
    </div>
  </div>
</div>
