export const menuSettings = [
  {
    icon: "assignment",
    title: "Job",
    description: "",
    route: "/fonction",
  },
  {
    icon: "ballot",
    title: "Categorie",
    description: "",
    route: "/category",
  },
  {
    icon: "home_work",
    title: "Departement",
    description: "",
    route: "/departement",
  },
  {
    icon: "verified_user",
    title: "Role",
    description: "",
    route: "/role",
  },
  {
    icon: "workspace",
    title: "Produits",
    description: "",
    route: "",
  },
  {
    icon: "store_front",
    title: "Fournisseurs",
    description: "",
    route: "",
  },
];
