import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { TablePaginateComponent } from "../../shared/components/table-paginate/table-paginate.component";
import { Router, RouterOutlet } from "@angular/router";

@Component({
  selector: "app-job",
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatRippleModule,
    HeaderComponent,
    TablePaginateComponent,
    RouterOutlet,
  ],
  templateUrl: "./job.component.html",
  styleUrl: "./job.component.scss",
})
export class JobComponent {
  constructor(private route: Router) {}

  handleAdd() {
    this.route.navigate(["/fonction/add"]);
  }
  handleUpload() {
    this.route.navigate(["/fonction/add/multiple"]);
  }
  handleEdit(item: any) {
    console.log("edit", item);
  }
  handleDelete(item: any) {
    console.log("delete", item);
  }

  isTableVisible() {
    return this.route.url.endsWith("/fonction");
  }
}
