<table mat-table [dataSource]="dataSourceMat" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="id">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
  
    <ng-container matColumnDef="name">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Nom</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    
    <ng-container matColumnDef="createdAt">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Date d'ajout</th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt }}</td>
    </ng-container>
    
    <ng-container matColumnDef="image">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Image</th>
      <td mat-cell *matCellDef="let element">
        <img [src]="element.image" [alt]="element.name" style="width: 200px;"/>
      </td>
    </ng-container>
    
    
  
    <ng-container matColumnDef="isActive">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>Statut</th>
      <td mat-cell *matCellDef="let element">
        @if(element.isActive){<mat-icon style="color: green;">check</mat-icon>}
        @else{<mat-icon style="color: red;">close</mat-icon>}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <app-table-actions-buttons
          [element]="element"
          (editHandler)="editHandler($event)"
          (deleteHandler)="deleteHandler($event)"></app-table-actions-buttons>
      </td>
    </ng-container> 
  
    <!-- Définition des lignes de la table -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  </table>
  
  <!-- Pagination -->
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons></mat-paginator>
  