import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../auth.service";
import { inject } from "@angular/core";

export const authenticationGuard: CanActivateFn = (route, state) => {
  const currentUser = inject(AuthService).getLoggedUser();
  if (currentUser) return true;
  inject(Router).navigate(["/"]);
  return false;
};
