import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UploadfileService {
  constructor() {}

  getFileSize(size: number): string {
    const fileSizeInBytes = size;
    const kibibyte = 1024;
    const megabyte = kibibyte * 1024;
    const gigabyte = megabyte * 1024;

    if (fileSizeInBytes >= gigabyte) {
      return `${(fileSizeInBytes / gigabyte).toFixed(2)} GB`;
    } else if (fileSizeInBytes >= megabyte) {
      return `${(fileSizeInBytes / megabyte).toFixed(2)} MB`;
    } else if (fileSizeInBytes >= kibibyte) {
      return `${(fileSizeInBytes / kibibyte).toFixed(2)} KB`;
    } else {
      return `${fileSizeInBytes} bytes`;
    }
  }

  isSupportedType(file: File, supportedType: string[]): boolean {
    const fileTypeParts = file.type.split("/");
    if (fileTypeParts.length === 2) {
      const fileExtension = fileTypeParts[1];
      console.log(fileExtension, fileTypeParts);
      return supportedType.includes(`.${fileExtension}`);
    }
    return false;
  }
}
