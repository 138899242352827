<table mat-table [dataSource]="dataSourceMat" class="mat-elevation-z8" matSort>
  <ng-container matColumnDef="userId">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element">{{ element.userId }}</td>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Nom</th>
    <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Prenom</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="isActive">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Statut du compte</th>
    <td mat-cell *matCellDef="let element">
      @if(element.isActive){<mat-icon>check</mat-icon>}
      @else{<mat-icon>close</mat-icon>}
    </td>
  </ng-container>

  <ng-container matColumnDef="contactPhone">
    <th mat-sort-header mat-header-cell *matHeaderCellDef>Contact</th>
    <td mat-cell *matCellDef="let element">{{ element.contactPhone }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <app-table-actions-buttons
        [element]="element"
        (editHandler)="editHandler($event)"
        (deleteHandler)="deleteHandler($event)"></app-table-actions-buttons>
    </td>
  </ng-container>

  <!-- Définition des lignes de la table -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<!-- Pagination -->
<mat-paginator
  [pageSizeOptions]="[5, 10, 25, 100]"
  showFirstLastButtons></mat-paginator>
