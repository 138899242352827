<div class="main-content">
    <app-header></app-header>
    <app-table-paginate
      *ngIf="isTableVisible()"
      dataType="category"
      (addEvent)="handleAdd()"
      (UploadEvent)="handleUpload()"
      (deleteEvent)="handleDelete($event)"
      (editEvent)="handleEdit($event)"></app-table-paginate>
    <router-outlet></router-outlet>
  </div>
  