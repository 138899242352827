import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { MonoTypeOperatorFunction } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DepartementService {
  _apiUrl = environment._apiURL + "/departement";
  constructor(private http: HttpClient) {}

  getAllDepartement() {
    return this.http.get(this._apiUrl);
  }

  saveDepartement(departement: any) {
    return this.http.post(this._apiUrl, departement);
  }
}
