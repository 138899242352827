import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import {
  MatDatepickerIntl,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { NgxMaskDirective } from "ngx-mask";
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { Router } from "@angular/router";
import { DepartementService } from "../../../services/departement/departement.service";
import { IDepartement } from "../../../models/interfaces/IDepartement.interface";
import Swal from "sweetalert2";

@Component({
  selector: "app-departement-add",
  standalone: true,
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    NgxMaskDirective,
    MatIcon,
  ],
  templateUrl: "./departement-add.component.html",
  styleUrl: "./departement-add.component.scss",
})
export class DepartementAddComponent {
  deptFormGroup = this._formBuilder.group({
    departmentName: ["", Validators.required],
  });

  constructor(
    private deptService: DepartementService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _adapter: DateAdapter<any>,
    private _intl: MatDatepickerIntl,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {}

  isFormValid() {
    return !!this.deptFormGroup.valid;
  }

  submit() {
    const dept = this.deptFormGroup.value;
    const newDept: IDepartement = Object.assign(dept);

    this.deptService.saveDepartement(newDept).subscribe(res => {
      Swal.fire({
        title: "Success!",
        text: "Departement crée avec succès",
        icon: "success",
        confirmButtonText: "Ok",
      }).then(response => {
        if (response.isConfirmed) {
          this.router.navigate(["/departement"]);
        }
      });
    });
  }
}
