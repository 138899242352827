import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
import { LoaderComponent } from "./shared/components/loader/loader.component";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  imports: [RouterOutlet, SidebarComponent, CommonModule, LoaderComponent],
})
export class AppComponent implements AfterViewInit {
  title = "mangabe";
  showSidebar: boolean = true;
  @ViewChild("sidebar")
  private sidebarComponent!: SidebarComponent;

  constructor(private router: Router) {}

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSidebar =
          this.isLoginPage() || this.isErrorPage() ? false : true;
        //update the active one of sidebar menu
        if (this.showSidebar && this.sidebarComponent) {
          const currentUrl = event.urlAfterRedirects;
          this.sidebarComponent.updateActiveMenus(currentUrl);
        }
      }
    });
  }

  private isLoginPage(): boolean {
    return this.router.url === "/" || this.router.url.startsWith("/login");
  }

  private isErrorPage(): boolean {
    return this.router.url.includes("/error");
  }
}
