import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { UploadfileService } from "../../services/uploadfile/uploadfile.service";
import { ButtonComponent } from "../button/button.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-upload-file",
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: "./upload-file.component.html",
  styleUrl: "./upload-file.component.scss",
})
export class UploadFileComponent {
  @Input() title: string = "Import multiple";
  @Input() btnLabel: string = "Choisir un fichier";
  @Input() supportedType!: string[];
  @Input() multiple: boolean = false;
  @Output() onFileSelected: EventEmitter<File[]> = new EventEmitter<File[]>();
  selectedFiles: File[] = [];

  constructor(private uploadService: UploadfileService) {}

  fileSelectedHandler(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (!this.multiple) this.selectedFiles = [];
      for (const file of files) {
        if (this.uploadService.isSupportedType(file, this.supportedType)) {
          this.selectedFiles.push(file);
          this.onFileSelected.emit(this.selectedFiles);
        } else {
          Swal.fire({
            title: "Error",
            text: "Type de Fichier(s) non pris en compte. Veuillez réessayer avec un autre type de fichier!",
            icon: "error",
          });
        }
      }
      this.onFileSelected.emit(this.selectedFiles);
    }
  }

  getFileSize(size: number): string {
    return this.uploadService.getFileSize(size);
  }

  removeFile(file: File) {
    const index = this.selectedFiles!.indexOf(file);
    if (index !== -1) {
      this.selectedFiles?.splice(index, 1);
    }
  }
}
