<div class="main-content">
  <app-header></app-header>

  <div class="card-container">
    @for (item of settingsList; track item) {
    <mat-card class="setting-card" matRipple (click)="clickHandler(item.route)">
      <mat-card-header>
        <mat-card-title class="card-settings-title"
          ><mat-icon class="material-icons-sharp">{{
            item.icon
          }}</mat-icon></mat-card-title
        >
        <mat-card-subtitle class="card-settings-subtitle">{{
          item.title
        }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        {{ item.description }}
      </mat-card-content>
    </mat-card>
    }
  </div>
</div>
