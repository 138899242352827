import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RoleService } from '../../../../../services/role/role.service';
import { CategoryService } from '../../../../../services/category/category.service';
import { CommonModule } from '@angular/common';
import { TableActionsButtonsComponent } from '../table-actions-buttons/table-actions-buttons.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-table-category-data',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    CommonModule,
    TableActionsButtonsComponent,
    MatSortModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,],
  templateUrl: './table-category-data.component.html',
  styleUrl: './table-category-data.component.scss'
})
export class TableCategoryDataComponent {
  displayedColumns: any[] = [
    "id",
    "image",
    "name",
    "createdAt",
    "isActive",
    "actions",
  ];
  dataSourceMat!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource: any;

  @Output() editHandlerFn = new EventEmitter<any>();
  @Output() deleteHandlerFn = new EventEmitter<any>();

  constructor(private categoryService: CategoryService) {}

  ngAfterViewInit(): void {
    this.categoryService.getallCategoryListe().subscribe(data => {
      this.dataSource = data;
      if (this.dataSource.length > 0 && !!data) {
        this.dataSourceMat = new MatTableDataSource(this.dataSource);
        this.dataSourceMat.paginator = this.paginator;
        this.dataSourceMat.sort = this.sort;
        this.dataSourceMat.sort.sort({
          id: "id",
          start: "desc",
          disableClear: false,
        });

        this.dataSourceMat.data = this.dataSource.map((data: any) => {
          return { ...data, actions: null };
        });
      }
    });
  }

  editHandler(event: any) {
    this.editHandlerFn.emit(event);
  }
  deleteHandler(event: any) {
    this.deleteHandlerFn.emit(event);
  }

}
