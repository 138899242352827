import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import Swal from "sweetalert2";
import { environment } from "../../../environments/environment";
import { ILogin } from "../../models/interfaces/ILogin";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  _apiUrl = environment._apiURL;

  constructor(private http: HttpClient, private router: Router) {}

  signin(loginData: ILogin) {
    return this.http.post<any>(`${this._apiUrl}/login`, loginData).pipe(
      map(user => {
        const { accessToken, refreshToken, ...rest } = user;
        sessionStorage.setItem("currentUser", JSON.stringify(rest));
        sessionStorage.setItem("accessToken", JSON.stringify(accessToken));
        sessionStorage.setItem("refreshToken", JSON.stringify(refreshToken));
        return user;
      }),
      catchError(error => {
        if (error.status == 401) {
          Swal.fire({
            title: "Non autorisé",
            text: "Il semble que vous n'êtes pas autorisé à vous connecter.",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Erreur",
            text: error.statusText,
            icon: "error",
          });
        }

        return of(null);
      })
    );
  }

  getLoggedUser() {
    //si pas de token dans le storage, on redirect directement vers la login page
    let accessToken = sessionStorage.getItem("accessToken");
    if (!accessToken) return false;

    let currentUser = sessionStorage.getItem("currentUser");
    if (currentUser) return JSON.parse(currentUser);
    return null;
  }

  logout() {
    return this.http.post<any>(`${this._apiUrl}/logout`, {}).pipe(
      map(res => {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
        this.router.navigate(["/"]);
        return res;
      })
    );
  }

  isUserAuthenticated(): boolean {
    return !!sessionStorage.getItem("currentUser");
  }
}
