import { CdkStepper } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

export type ButtonType =
  | "default"
  | "primary"
  | "secondary"
  | "cancel"
  | "success"
  | "outline";
@Component({
  selector: "app-button",
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: "./button.component.html",
  styleUrl: "./button.component.scss",
})
export class ButtonComponent extends CdkStepper {
  @Input() label: string = "";
  @Input() iconPath: string = "";
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: ButtonType = "default";
  @Input() disable: boolean = false;
  @Input() isVisible: boolean = true;

  onClick() {
    this.clickEvent.emit();
  }
}
